.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px 0;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  max-width: 1280px;
}

.flex-line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.column-tables {
  display: flex;
  width: 100%;
}