* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap) format('truetype');
}
@font-face {
  font-family: "Open Sans";
  src: url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;700&display=swap) format("truetype");
  font-weight: 700;
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

body *{
  font-family: 'Open Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body, html {
  height: 100%
}

.MuiAutocomplete-option {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  padding: 6px !important;
}